<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onView(row)">查看</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { createId } from '@/lib/util';
import { reqDelFeedback, reqFeedback } from '@/lib/request/auth2';

export default {
    name: 'accountList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'keyword' }],
            dataColumn: [
                { key: 'userName', title: '用户名称', minWidth: 120 },
                { key: 'note', title: '反馈内容', minWidth: 250, tooltip: true },
                { key: 'tel', title: '手机号', minWidth: 120 },
                { key: 'ctime', title: '反馈时间', minWidth: 120 },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            console.log(JSON.stringify(this.params, null, '\t'));
            this.showAjaxLoading();
            reqFeedback(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //查看详情
        onView(row = {}) {
            this.goName('userFeedBackView', row);
        },
        //删除
        onDelete(info = {}) {
            let { id } = info || {};
            this.confirmDialog(`确认删除这么反馈吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelFeedback({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
    },
};
</script>
