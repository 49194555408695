<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="groupid">
                    {{ (roleList.find(role => role.id == row.groupid) || {}).groupname || row.groupid || '-' }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <Button type="default" @click="switchStatus(row)" size="small" :class="'now_state_' + row.status">{{ { 0: '关闭', 1: '启用' }[row.status] }}</Button>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" @click="onEdit(row)">编辑</Button>
                        <!-- <Button type="error" size="small" ghost @click="onDelete(row.id)">删除</Button> -->
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqAccountList, reqGroupList, reqSwitchStatus } from '@/lib/request/auth';

export default {
    name: 'accountList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            roleList: [],
            searchs: [
                { id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'nickname' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '启用' },
                        { id: '0', title: '关闭' },
                    ],
                },
            ],
            dataColumn: [
                { key: 'id', title: 'ID', minWidth: 80, tooltip: true },
                { key: 'username', title: '账号', minWidth: 70, tooltip: true },
                { key: 'nickname', title: '名称', minWidth: 80, tooltip: true },
                { slot: 'groupid', title: '角色', minWidth: 80 },
                {
                    key: 'last_login_time',
                    title: '最后登录时间',
                    minWidth: 140,
                    render(h, data) {
                        return h('div', new Date(data.row.last_login_time * 1000).Format('yyyy-MM-dd HH:mm:ss') + '');
                    },
                },
                { key: 'last_login_ip', title: '最后登录IP', minWidth: 120 },
                { slot: 'status', title: '状态', minWidth: 60 },
                { slot: 'manager', title: '操作', minWidth: 130 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getRoleList();
    },
    methods: {
        switchStatus(row) {
          this.showAjaxLoading();
          row.status = (row.status|0) ? 0 : 1;
          reqSwitchStatus({
            id: row.id,
            status: row.status + ''
          }).then(res => {

          }).finally(() => {
            this.hideAjaxLoading();
            this.getList();
          });
        },
        getRoleList() {
            this.showAjaxLoading();
            reqGroupList(this.params)
                .then(res => {
                    this.roleList = res.data.list || [];
                })
                .finally(() => {
                    this.hideAjaxLoading();
                    this.getList();
                });
        },
        getList() {
            this.showAjaxLoading();
            reqAccountList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_row) {
            let params = hm.isNotNullObject(_row) ? _row : { create: 1 };
            this.goName('sysManagerAccountAdd', params);
        },
        //删除
        onDelete(id) {
            this.confirmDialog('确认删除吗').then(isOK => {
                if (isOK == 1) {
                    console.log('onDelete', id);
                }
            });
        },
    },
};
</script>
